<template>
    <div>
        <div class="button">
            <div class="success">绑定成功</div>
            <van-button color="#EB5E00" size="large" @click="submit">返回首页</van-button>
        </div>
    </div>
</template>
<script>
import { Toast} from 'vant';
export default {
    name:'BindingSuccess',
    data() {
        return {
            arrs:{}
        }
    },
    methods: {
        submit(){
            this.$router.push({name:'Home'});
        }
    },
    created() {
        Toast.success('绑定成功');
        this.$store.commit('setPageTitle', "绑定成功");
    }
}
</script>
<style lang="scss" scoped>
.price{
    span{
        color: #EB5E00;font-size: 60px;
    }
}
.card{
    text-align: left;
}
.button{
    margin-top: 50px
}
.success{
    margin-top: 50px;color: #55bb82;text-align: center;
}
</style>